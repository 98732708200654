<template>
  <div
    class="flex flex-col items-center justify-end pb-20 text-center w-full col-span-1 md:col-span-10"
  >
    <are-you-sure
      :trigger="deleteApunte"
      @toggle="
        event => {
          event == 'save' ? deleteNullReservation(deleteApunteNote) : '';
          deleteApunte = false;
          deleteApunteNote = null;
        }
      "
    />
    <modal-finish-block
      :trigger="finishToogle"
      @toggle="
        event => {
          event.status == 'save' ? finishBlock(finishToogle, event.data) : '';
          finishToogle = null;
        }
      "
    />
    <div
      v-if="$route.query.block"
      class="flex flex-end items-center bg-white rounded-lg overflow-hidden border border-gray-200 w-full mb-4 px-3 py-4"
    >
      <a
        v-if="user.doctor.meeting_url"
        :href="
          user.doctor.meeting_url.includes('https://')
            ? user.doctor.meeting_url
            : `https://${user.doctor.meeting_url}`
        "
        target="_blank"
        class="cursor-pointer inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-600 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
      >
        <span>Iniciar Videollamada</span>
      </a>
      <button
        v-else
        class="cursor-not-allowed inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-gray-50 text-base font-medium text-gray-400 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
      >
        <span>Iniciar Videollamada</span>
      </button>
      <button
        v-if="
          block &&
            block.session &&
            block.session.is_activated &&
            !block.session.is_finish
        "
        type="button"
        @click="
          () => {
            finishToogle = $route.query.block;
          }
        "
        class="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
      >
        <span>Cerrar atención</span>
      </button>
      <button
        v-else
        class="cursor-not-allowed inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-gray-50 text-base font-medium text-gray-400 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
      >
        <span>Cerrar atención</span>
      </button>
    </div>
    <div
      class="align-start bg-white rounded-lg text-left overflow-hidden border border-gray-200 transform transition-all w-full"
    >
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div class="text-center sm:text-left w-full">
            <div
              v-if="meeting"
              class="w-full flex flex-row justify-between items-center border border-gray-200 border-dashed mb-4 rounded-md py-3 px-4"
            >
              <button
                class="text-sm flex flex-row text-gray-500 text-left font-semibold"
                v-if="meeting_sent"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span>Enviado</span>
              </button>
              <button
                class="text-sm flex flex-row text-gray-500 text-left font-semibold"
                v-if="meeting_loading"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="animate-spin h-5 w-5 mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span>Enviando</span>
              </button>
              <input
                v-if="!meeting_sent && !meeting_loading"
                v-model="meeting_url"
                type="text"
                class="appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Enlace de videollamada"
              />
              <div class="flex flex-row">
                <button
                  v-if="!meeting_sent && !meeting_loading"
                  type="button"
                  @click="send_email()"
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-600 shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-600 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  <span>Enviar</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-gray-600 ml-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
                    />
                    <path
                      d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                    />
                  </svg>
                </button>
                <button
                  type="button"
                  @click="
                    () => {
                      meeting = false;
                    }
                  "
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  <span>cerrar</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-gray-600 ml-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div class="w-full grid grid-cols-1 md:grid-cols-2 mb-4 gap-4">
              <div
                class="w-full border border-gray-200 border-dashed rounded-md py-3 px-4"
              >
                <div class="w-full">
                  <span
                    class="text-md leading-6 font-medium text-left w-full text-gray-900"
                    >Crear Apunte</span
                  >
                  <textarea
                    name="note"
                    rows="5"
                    v-model="note_bool_url"
                    class="shadow-sm h-64 resize-none focus:ring-green-500 focus:border-green-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  ></textarea>
                </div>
                <div class="flex flex-row justify-end mt-3 w-full">
                  <button
                    type="button"
                    @click="note_create()"
                    class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-600 shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-600 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                  >
                    <span>Crear</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="flex flex-row justify-start items-center mb-2">
              <span class="text-md w-full text-left font-medium text-gray-900"
                >Reservas y apuntes</span
              >
              <div class="w-full flex flex-row justify-end items-center">
                <button
                  v-if="false"
                  type="button"
                  @click="
                    () => {
                      note_bool = true;
                    }
                  "
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  <span>Crear apunte</span>
                </button>
                <!-- <button
                  type="button"
                  @click="
                    () => {
                      meeting_bool = true;
                    }
                  "
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  <span>Crear videollamada</span>
                </button> -->
              </div>
            </div>
            <div
              v-if="meeting_bool"
              class="w-full flex flex-row justify-between items-center border border-gray-200 border-dashed mb-2 rounded-md py-3 px-4"
            >
              <input
                v-model="meeting_bool_url"
                type="text"
                class="appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Enlace de videollamada"
              />
              <div class="flex flex-row w-full">
                <button
                  type="button"
                  @click="meeting_create()"
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-600 shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-600 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  <span>Guardar y enviar</span>
                </button>
                <button
                  type="button"
                  @click="
                    () => {
                      meeting_bool = false;
                    }
                  "
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  <span>cerrar</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-gray-600 ml-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div class="flex flex-row justify-start items-center mb-2">
              <span
                v-if="notes.length == 0"
                class="text-sm w-full text-left font-normal text-gray-800"
                >Sin reservas ni apuntes</span
              >
            </div>
            <div
              v-for="(note, noteIndex) in notes"
              :key="noteIndex"
              class="w-full mb-4 border border-gray-200 border-dashed rounded-md py-3 px-4"
            >
              <div class="flex items-center">
                <div class="flex-shrink-0 h-10 w-10">
                  <div
                    class="h-10 w-10 rounded-full bg-gray-200 flex justify-center items-center text-gray-500"
                  >
                    <svg
                      v-if="note.id"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <svg
                      v-if="!note.id && note.meeting"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
                      />
                    </svg>
                    <svg
                      v-if="!note.id && note.note"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                      />
                    </svg>
                  </div>
                </div>
                <div class="ml-4 flex flex-col justify-start items-center">
                  <div
                    v-if="note.id"
                    class="text-sm font-light w-full text-left text-gray-700"
                  >
                    <strong class="text-gray-900">Creado</strong>
                    {{ formatToHour(note.created_at) }}
                  </div>
                  <div
                    v-if="!note.id && note.meeting"
                    class="text-sm font-light w-full text-left text-gray-700"
                  >
                    <strong class="text-gray-900">Llamada creada</strong>
                    {{ formatToHour(note.meeting.created_at) }}
                  </div>
                  <div
                    v-if="!note.id && note.note"
                    class="text-sm font-light w-full text-left text-gray-700"
                  >
                    <strong class="text-gray-900">Apunte creado</strong>
                    {{ formatToHour(note.note.created_at) }}
                  </div>
                  <div
                    v-if="note.meeting"
                    class="flex flex-row text-sm font-light w-full text-left text-gray-700"
                  >
                    <strong v-if="note.id" class="text-gray-900">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 mr-2"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
                        />
                      </svg>
                    </strong>
                    <a
                      class="underline font-bold text-green-600 hover:text-green-800 visited:text-green-700 cursor-pointer"
                      v-if="note.meeting"
                      :href="note.meeting.url"
                      >{{ note.meeting.url }}</a
                    >
                  </div>
                  <div
                    v-if="note.id || (!note.id && note.note)"
                    class="text-sm w-full text-left font-medium text-gray-900 capitalize"
                  >
                    {{ note.block.user.names }}
                    {{ note.block.user.lastnames }}
                  </div>
                  <div
                    v-if="note.id"
                    class="text-sm font-light w-full text-left text-gray-700"
                  >
                    <strong class="text-gray-900">Hora atención</strong>
                    {{ formatToHour(note.block.start_date) }}
                  </div>
                  <div
                    v-if="note.id && note.note"
                    class="text-sm font-light w-full text-left text-gray-700"
                  >
                    <strong class="text-gray-900">Hora del apunte</strong>
                    {{ formatToHour(note.note.updated_at) }}
                  </div>
                  <div
                    v-if="note.note"
                    style="white-space: pre-line"
                    class="text-sm w-full text-left text-gray-500"
                  >
                    {{ note.note.results }}
                  </div>
                  <div
                    v-if="!note.note && !note.meeting"
                    class="text-sm w-full text-left text-gray-500"
                  >
                    Sin apuntes
                  </div>
                  <div
                    v-if="
                      !note.id &&
                        (note.meeting ||
                          (note.note && note.block.user.id == user.id))
                    "
                    class="text-sm w-full text-left text-gray-500"
                  >
                    <button
                      v-if="showDelete(note)"
                      @click="
                        () => {
                          deleteApunte = true;
                          deleteApunteNote = note;
                        }
                      "
                      class="mt-2 bg-red-50 flex flex-row-reverse justify-between items-center hover:bg-red-100 text-red-700 rounded-md text-sm px-2 py-1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <span class="mr-2">eliminar</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlockService from '@/services/blockService';
import DoctorService from '@/services/doctorService';
import PatientService from '@/services/patientService';
import cie10 from 'cie10';
import AreYouSure from '@/components/modals/AreYouSure.vue';
import FinishBlock from '@/views/dashboard/user/doctor/calendar/finish.vue';
import { mapGetters } from 'vuex';

import moment from 'moment';

export default {
  name: 'Ficha',
  components: {
    'are-you-sure': AreYouSure,
    'modal-finish-block': FinishBlock,
  },
  data() {
    return {
      results: '',
      deleteApunte: false,
      deleteApunteNote: null,
      notes: [],
      cie10_array: cie10('obj'),
      code: '',
      ges: '',
      save_button: false,
      block: null,
      meeting: false,
      meeting_url: '',
      meeting_sent: false,
      meeting_loading: false,
      meeting_bool: false,
      meeting_bool_url: '',
      note_bool: false,
      note_bool_url: '',
      note_sent: false,
      note_loading: false,
      note_url: '',
      editApunte: false,
      patient: null,
      finishToogle: null,
    };
  },
  created() {
    this.updateData();
  },
  methods: {
    finishBlock(
      block,
      { finish_type, extension, hospitalization, prescription }
    ) {
      BlockService.finishBlock({
        id: block,
        finish_type,
        extension,
        hospitalization,
        prescription,
      })
        .then(() => {
          this.updateData();
        })
        .catch(() => {
          console.log('error finishing this block');
        });
    },
    getPatient() {
      PatientService.getPatientFromDoctor(
        this.block.reservation.purchase.user_id
      )
        .then(response => {
          this.block.reservation.purchase.user = response.data.patient;
          this.patient = response.data.patient;
          this.scroll();
          this.checkBlock();
        })
        .catch(() => {
          this.$router.replace({ path: '/doctor/calendar' });
        });
    },
    updateData() {
      if (this.$route.query.block) {
        BlockService.getBlock(this.$route.query.block)
          .then(response => {
            this.block = response.data.block;
            this.getPatient();
          })
          .catch(() => {
            console.log('error getting block');
          });
      } else {
        this.block = {
          reservation: {
            purchase: {
              user_id: this.$route.params.user_id,
            },
          },
        };
        this.getPatient();
      }
    },
    showDelete(note) {
      if (!note) {
        return false;
      }

      let created = moment(note.created_at, 'YYYY-MM-DD H:mm');
      let created_next_day = created.clone().add(1, 'd');

      let now = moment();
      if (now.isAfter(created_next_day)) {
        return false;
      }
      return true;
    },
    editionTime(note) {
      if (!note) {
        return true;
      }

      let created = moment(note.created_at, 'YYYY-MM-DD H:mm');
      let created_next_day = created.clone().add(1, 'd');

      let now = moment();
      if (now.isAfter(created_next_day)) {
        return false;
      }
      return true;
    },
    deleteNullReservation(reservation) {
      if (!reservation.id) {
        if (reservation.note && reservation.block.user.id == this.user.id) {
          DoctorService.noteDelete(reservation.note.id)
            .then(() => {
              BlockService.getNotes(this.block.reservation.purchase.user_id)
                .then(response => {
                  this.notes = response.data.sort(function(a, b) {
                    return new Date(b.created_at) - new Date(a.created_at);
                  });
                })
                .catch(() => {
                  console.log('error getting notes');
                });
            })
            .catch(() => {
              console.log('error deleting note');
            });
        }
        if (reservation.meeting) {
          DoctorService.meetingDelete(reservation.meeting.id)
            .then(() => {
              BlockService.getNotes(this.block.reservation.purchase.user_id)
                .then(response => {
                  this.notes = response.data.sort(function(a, b) {
                    return new Date(b.created_at) - new Date(a.created_at);
                  });
                })
                .catch(() => {
                  console.log('error getting notes');
                });
            })
            .catch(() => {
              console.log('error deleting meeting');
            });
        }
      }
    },
    meeting_create() {
      if (
        this.meeting_bool_url &&
        this.block &&
        this.block.reservation &&
        this.block.reservation.purchase
      ) {
        DoctorService.meetingCreate({
          url: this.meeting_bool_url,
          id: this.block.reservation.purchase.user_id,
        })
          .then(() => {
            BlockService.getNotes(this.block.reservation.purchase.user_id)
              .then(response => {
                this.notes = response.data.sort(function(a, b) {
                  return new Date(b.created_at) - new Date(a.created_at);
                });
                this.meeting_bool = false;
              })
              .catch(() => {
                console.log('error getting notes');
              });
          })
          .catch(() => {
            console.log('error creating meeting');
          });
      }
    },
    note_create() {
      if (
        this.note_bool_url &&
        this.block &&
        this.block.reservation &&
        this.block.reservation.purchase
      ) {
        DoctorService.noteCreate({
          results: this.note_bool_url,
          id: this.block.reservation.purchase.user_id,
        })
          .then(() => {
            BlockService.getNotes(this.block.reservation.purchase.user_id)
              .then(response => {
                this.notes = response.data.sort(function(a, b) {
                  return new Date(b.created_at) - new Date(a.created_at);
                });
                this.note_bool = false;
                this.note_bool_url = '';
              })
              .catch(() => {
                console.log('error getting notes');
              });
          })
          .catch(() => {
            console.log('error creating note');
          });
      }
    },
    send_email() {
      this.meeting_loading = true;
      BlockService.meeting({
        id: this.block.id,
        url: this.meeting_url,
      })
        .then(() => {
          this.meeting_loading = false;
          this.meeting_sent = true;
        })
        .catch(() => {
          console.log('error sending the email');
        });
    },
    formatToHour(date) {
      return moment(date, 'YYYY-MM-DD H:mm').format('llll');
    },
    save() {
      if (this.results) {
        this.save_button = true;
        BlockService.final({
          id: this.block.id,
          //code: this.code,
          //ges: this.ges,
          results: this.results,
        })
          .then(response => {
            //this.$emit('toggle', 'save');
            this.block.reservation.note = response.data;
            this.checkBlock();
            this.save_button = false;
            this.editApunte = false;
          })
          .catch(() => {
            this.error = 'Error de servidor!';
            this.save_button = false;
          });
      }
    },
    saveDelete() {
      this.save_button = true;
      BlockService.final({
        id: this.block.id,
        //code: this.code,
        //ges: this.ges,
        results: '',
      })
        .then(() => {
          //this.$emit('toggle', 'save');
          this.block.reservation.note = null;
          this.checkBlock();
          this.save_button = false;
          this.editApunte = false;
        })
        .catch(() => {
          this.error = 'Error de servidor!';
          this.save_button = false;
        });
    },
    scroll() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    checkBlock() {
      if (
        this.block &&
        this.block.reservation &&
        this.block.reservation.purchase
      ) {
        BlockService.getNotes(this.block.reservation.purchase.user_id)
          .then(response => {
            this.notes = response.data.sort(function(a, b) {
              return new Date(b.created_at) - new Date(a.created_at);
            });
          })
          .catch(() => {
            console.log('error getting notes');
          });
      }
      if (this.block && this.block.reservation && this.block.reservation.note) {
        this.results = this.block.reservation.note.results;
      }
    },
  },
  watch: {
    '$route.params.user_id'() {
      this.updateData();
    },
    '$route.query.block'() {
      this.updateData();
    },
    meeting_bool() {
      this.meeting_bool_url = '';
    },
    note_bool() {
      this.note_bool_url = '';
    },
    meeting() {
      this.meeting_url = '';
    },
    code() {
      this.code = this.code.toUpperCase();
      let temp = this.cie10_array[this.code];
      if (this.code && temp) {
        this.ges = temp;
      } else {
        this.ges = '';
      }
    },
  },
  computed: {
    ...mapGetters({
      user: ['authentication/user'],
    }),
  },
};
</script>
